import { ButtonQuantity } from './styles/RoiCalculatorCost';

type Props = {
  quantity: number;
  setQuantity: (quantity: number) => void;
};

const InputQuantity = ({ quantity, setQuantity }: Props) => {
  const quantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const isNumber = /^[0-9]*$/gm.test(value);
    setQuantity(Number(isNumber ? value : 1));
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#0b0b0b', padding: 5 }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ButtonQuantity onClick={() => setQuantity(quantity >= 1 ? quantity + 1 : 1)}>+</ButtonQuantity>
        <ButtonQuantity onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}>-</ButtonQuantity>
      </div>
      <input
        style={{
          width: 84,
          height: 40,
          paddingRight: 10,
          textAlign: 'right',
          backgroundColor: '#0b0b0b',
          color: '#FAFAFA',
          borderWidth: 0
        }}
        value={quantity}
        type="text"
        onChange={(event) => quantityChange(event)}
      />
    </div>
  );
};

export default InputQuantity;
