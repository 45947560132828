import { Col } from 'react-awesome-styled-grid';

import { ItemCostSection, ItemRoiItem, RoiCalculatorCostProps } from '../../types/roicalculator';
import Markdown from '../Markdown';
import InputCost from './InputCost';
import InputQuantity from './InputQuantity';
import RoiElectronicParts from './RoiElectronicParts';
import { Checkbox, RowStyled, TooltipBox, TooltipCard, TooltipText } from './styles/RoiCalculatorCost';

const RoiCalculatorCost = ({
  items,
  checkParts,
  handleCheckParts,
  totalOther,
  quantity,
  totalElectronic,
  totalCashInDevice,
  setQuantity,
  checkElectronic,
  handleCheckElectronic,
  costMetrics,
  handleChangeCostMetric,
  exploitMetrics,
  handleChangeExploitMetric
}: RoiCalculatorCostProps) => {
  // functions
  const getDescription = (
    subitem: ItemCostSection,
    item: ItemRoiItem,
    key: string,
    costPerUnit: string,
    priceColCheckbox: number,
    validationColCheck: boolean | undefined
  ) => {
    const electronicDescription =
      totalElectronic > 0 ? `$${String(totalElectronic + totalCashInDevice)}` : subitem?.description;
    const validationCheck = validationColCheck ? `$${priceColCheckbox}` : subitem?.description;
    const description = subitem?.subdescription
      ? '$' + costPerUnit
      : key === 'subitem-3' && item.type !== 'col-checkbox'
      ? electronicDescription
      : validationCheck;
    return description;
  };

  const getBackground = (item: ItemRoiItem, subitem: ItemCostSection, backgroundValidation: string) => {
    return subitem?.code === 'full-diy-kit' ||
      subitem?.code === 'electronic-parts' ||
      subitem?.type === 'label' ||
      item?.code === 'cost-metrics' ||
      item?.code === 'exploitation-metrics'
      ? ''
      : backgroundValidation;
  };

  const getBackgroundValidation = (item: ItemRoiItem, subitem: ItemCostSection) => {
    return (item.type === 'row-checkbox' && subitem.type === 'checkbox') ||
      subitem?.subdescription ||
      subitem?.type !== 'input'
      ? '#0b0b0b'
      : '';
  };

  const getPriceColCheckbox = (item: ItemRoiItem, subitem: ItemCostSection) => {
    return item.type === 'col-checkbox' && subitem.type !== 'input'
      ? Number(subitem?.description?.replace(/[^0-9.-]+/g, '')) * quantity
      : 0;
  };

  return (
    <div>
      {items.map((item, index) => (
        <Col key={`item-${index}`} style={{ marginBottom: 30, padding: 0 }}>
          <Col style={{ padding: 0, marginBottom: 10, fontSize: 28, fontStyle: 'normal', fontWeight: 500 }}>
            {item.title}
          </Col>

          {item.items.map((subitem: ItemCostSection, subindex: number) => {
            const totalPrice = String((totalOther + totalElectronic + totalCashInDevice) * quantity);
            const key = `subitem-${subindex}`;
            const price = Number(subitem?.description?.split('$')[1]);
            const costPerUnit = !isNaN(Number(totalPrice) / quantity)
              ? (Number(totalPrice) / quantity).toFixed(0) + ' per unit'
              : 0 + ' per unit';
            const validationColCheck = item.type === 'col-checkbox' && subitem.type !== 'input' && subitem?.isquantity;
            const priceColCheckbox = getPriceColCheckbox(item, subitem);
            const description = getDescription(subitem, item, key, costPerUnit, priceColCheckbox, validationColCheck);
            const backgroundValidation = getBackgroundValidation(item, subitem);

            return (
              <Col key={key} style={{ marginBottom: 5, marginTop: 5 }}>
                <RowStyled
                  style={{
                    backgroundColor: getBackground(item, subitem, backgroundValidation),
                    paddingTop: 4,
                    paddingBottom: 4,
                    cursor: item.type === 'row-checkbox' && subitem.type === 'checkbox' ? 'pointer' : '',
                    alignItems: 'center',
                    marginTop: subitem?.type === 'label' || subitem?.code === 'quantity' ? 20 : 0
                  }}
                  onClick={() => {
                    if (item.type === 'row-checkbox' && subitem.type === 'checkbox') {
                      handleCheckParts(
                        { target: { checked: !checkParts[key] } } as React.ChangeEvent<HTMLInputElement>,
                        key,
                        price
                      );
                    }
                  }}
                >
                  <Col
                    md={4}
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 0, height: 48 }}
                  >
                    {item.type === 'row-checkbox' && subitem.type === 'checkbox' && (
                      <Checkbox
                        checked={checkParts[key] || false}
                        onChange={(event) => handleCheckParts(event, key, price)}
                      />
                    )}
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          className={subitem?.subdescription ? 'description-host-gray' : ''}
                          style={subitem?.type === 'label' ? { fontSize: 26 } : { fontSize: 20 }}
                        >
                          {subitem.title}
                        </span>
                        {item.type === 'col-checkbox' && (
                          <TooltipCard>
                            <TooltipText>
                              <span>?</span>
                            </TooltipText>
                            <TooltipBox>{subitem?.help}</TooltipBox>
                          </TooltipCard>
                        )}
                      </div>
                      {subitem?.subtitle && (
                        <span className="description-host-gray" style={{ fontSize: 18 }}>
                          {subitem.subtitle}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col md={4} style={{ alignItems: 'flex-end' }}>
                    <span>{subitem?.subdescription ? `$${totalPrice}` : ''}</span>
                    <Markdown
                      className="description-host-gray"
                      content={
                        item?.code === 'cost-metrics' || item?.code === 'exploitation-metrics' ? '' : description
                      }
                    />
                    {subitem?.type === 'input' && <InputQuantity quantity={quantity} setQuantity={setQuantity} />}
                    {item?.code === 'cost-metrics' && (
                      <InputCost
                        code={key}
                        value={String(costMetrics[key])}
                        handleChange={handleChangeCostMetric}
                        subitem={subitem}
                      />
                    )}
                    {item?.code === 'exploitation-metrics' && (
                      <InputCost
                        code={subitem?.code || ''}
                        value={String(subitem?.code ? exploitMetrics[subitem.code] : 0)}
                        handleChange={handleChangeExploitMetric}
                        subitem={subitem}
                      />
                    )}
                  </Col>
                </RowStyled>

                {subitem?.items && subitem.items.length > 0 && (
                  <RoiElectronicParts
                    items={subitem.items || []}
                    checkElectronic={checkElectronic}
                    handleCheckElectronic={handleCheckElectronic}
                    checkParts={checkParts}
                  />
                )}
              </Col>
            );
          })}
        </Col>
      ))}
    </div>
  );
};

export default RoiCalculatorCost;
