import { graphql, navigate, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col, Container, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import useWindowSize from '../../hooks/useWindowSize';
import deviceBreakPoints from '../../styles/breakpoints';
import { renderIcon } from '../../utils/icons';
import ArrowedLink from '../ArrowedLink';
import CaptionedTitle from '../CaptionedTitle';
import Markdown from '../Markdown';
import ThemedBackground from '../ThemedBackground';

type Item = {
  title: string;
  description: string;
  icon: string;
  buttontext: string;
  url: string;
};

const ReadyToHost: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    readyToHostSection: { title, description, items }
  } = data.edges[0].node;
  const { isMobile } = useWindowSize();

  return (
    <ThemedBackground as="section">
      <Container>
        <Col style={{ paddingTop: isMobile ? 0 : 150, marginBottom: 20 }}>
          {isMobile ? (
            <Title style={{ fontSize: 32 }}>{title}</Title>
          ) : (
            <CaptionedTitle className="caption-why-choose" as="h3">
              {title}
            </CaptionedTitle>
          )}
          <DescriptionContainer>
            <Markdown className="description-host-gray" content={description} />
          </DescriptionContainer>
        </Col>
        <Row style={{ padding: 5, justifyContent: 'start' }}>
          {items.map((item: Item, index: number) => {
            const iconClass = 'icon-svg-outline-white';
            const isUrl = item.url.length > 0;
            return (
              <Col key={`item-${index}`} md={2.5} lg={3.9} xl={3.9} style={{ margin: 5 }}>
                <Card onClick={isUrl ? () => navigate(item.url) : undefined} isUrl={isUrl}>
                  <IconContainer>{renderIcon(item.icon, true, iconClass)}</IconContainer>
                  <Title>{item.title}</Title>
                  <CardDescriptionContainer>
                    <Markdown className="description-host-gray" content={item.description} />
                  </CardDescriptionContainer>
                  <div style={{ padding: 5 }}>
                    <ArrowedLink to={item.url}>{item.buttontext}</ArrowedLink>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </ThemedBackground>
  );
};

export default ReadyToHost;

const DescriptionContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 38%;
  @media ${deviceBreakPoints.mobile} {
    width: 100%;
  }
`;

const Title = styled.h4`
  font-size: 24px;
  font-weight: 400;
  line-height: 1.33;
  margin-bottom: 10px;
`;

const Card = styled.div<{ isUrl?: boolean }>`
  background-color: #292929;
  padding: 15px;
  cursor: ${({ isUrl }) => (isUrl ? 'pointer' : 'not-allowed')};
  &:hover {
    background-color: #3c3c3c;
  }
`;

const CardDescriptionContainer = styled.div`
  height: 180px;
`;

const IconContainer = styled.div`
  height: 5px;
`;

const query = graphql`
  query {
    data: allHostPageYaml {
      edges {
        node {
          readyToHostSection {
            title
            description
            items {
              title
              description
              icon
              buttontext
              url
            }
          }
        }
      }
    }
  }
`;
