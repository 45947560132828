import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col, Container, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import useWindowSize from '../../hooks/useWindowSize';
import DashboardImage from '../../images/svgs/host/dashboard-example.svg';
import DashboardLeft from '../../images/svgs/host/dashboard-left.svg';
import DashboardRight from '../../images/svgs/host/dashboard-right.svg';
import deviceBreakPoints from '../../styles/breakpoints';
import { renderIcon } from '../../utils/icons';
import Markdown from '../Markdown';
import ThemedBackground from '../ThemedBackground';

const LeftContent = ({ title, items, isMobile }) => {
  return (
    <Col md={3} style={{ marginRight: 20 }}>
      <Title>{title}</Title>
      {items.map((item, index) => (
        <RowStyled key={`item-${index}`} style={{ marginBottom: isMobile ? 30 : 10 }}>
          <Col xs={0.4} sm={0.4} md={0.6}>
            {renderIcon(item.icon, true, 'icon-versatile')}
          </Col>
          <Col xs={3.6} sm={7.6} md={7.4}>
            <Markdown className="description-host-gray" content={item.description} />
          </Col>
        </RowStyled>
      ))}
    </Col>
  );
};

const RightContent = ({ isMobile }) => {
  return (
    <Col
      md={4.8}
      style={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'relative',
        padding: 0,
        right: 0,
        paddingTop: 35
      }}
    >
      {isMobile ? (
        <>
          <Col style={{ padding: 0 }}>
            <DashboardImageLeft />
          </Col>
          <Col style={{ padding: 0, marginTop: 20 }}>
            <DashboardImageRight />
          </Col>
        </>
      ) : (
        <DashboardImageStyled />
      )}
    </Col>
  );
};

const VersatileSection: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    versatileSection: { title, items }
  } = data.edges[0].node;
  const { isMobile } = useWindowSize();

  return (
    <ThemedBackground as="section">
      <Container style={{ paddingBottom: !isMobile ? 40 : 0 }}>
        {isMobile ? (
          <Row style={{ padding: 10 }}>
            <RightContent isMobile={isMobile} />
            <LeftContent title={title} items={items} isMobile={isMobile} />
          </Row>
        ) : (
          <Row style={{ padding: 10 }}>
            <LeftContent title={title} items={items} isMobile={isMobile} />
            <RightContent isMobile={isMobile} />
          </Row>
        )}
      </Container>
    </ThemedBackground>
  );
};

export default VersatileSection;

const Title = styled.h4`
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 40px;
`;

const DashboardImageLeft = styled(DashboardLeft)`
  @media ${deviceBreakPoints.tablet} {
    width: 100%;
    height: 700px;
  }

  @media ${deviceBreakPoints.mobile} {
    width: 100%;
    height: auto;
  }
`;

const DashboardImageRight = styled(DashboardRight)`
  @media ${deviceBreakPoints.tablet} {
    width: 100%;
    height: 850px;
    margin-bottom: 60px;
  }

  @media ${deviceBreakPoints.mobile} {
    width: 100%;
    height: auto;
  }
`;

const DashboardImageStyled = styled(DashboardImage)`
  width: 100%;
  height: auto;
`;

const RowStyled = styled(Row)`
  &:hover {
    background-color: #181818;
  }
  cursor: pointer;
  padding: 16px;
`;

const query = graphql`
  query {
    data: allHostPageYaml {
      edges {
        node {
          versatileSection {
            title
            items {
              description
              icon
            }
          }
        }
      }
    }
  }
`;
