import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

type Props = {
  totalCost: number;
  revenue: number;
  costPerMonth: number;
};

const RoiCalculatorGraphic = ({ totalCost, revenue, costPerMonth }: Props) => {
  const isServer = typeof window === 'undefined';
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    if (isServer) {
      return;
    } else {
      setIsBrowser(true);
    }
  }, [isServer]);

  const categories = new Array(24).fill(0).map((_, index) => index + 1);

  const getTotalCost = () => {
    const first_value = totalCost;
    const array_length = 24;
    const result_array = [first_value];

    for (let i = 1; i < array_length; i++) {
      result_array.push(result_array[i - 1] + costPerMonth);
    }
    return result_array;
  };

  const getTotalRevenue = () => {
    const first_value = revenue;
    const array_length = 24;
    const result_array = [first_value];

    for (let i = 1; i < array_length; i++) {
      result_array.push(result_array[i - 1] + revenue);
    }
    return result_array;
  };

  const getTotalProfit = () => {
    const totalCostArray = getTotalCost();
    const revenueArray = getTotalRevenue();
    const resultArray = new Array(24).fill(0).map((_, index) => {
      const result = revenueArray[index] - totalCostArray[index];
      return result < 0 ? 0 : result;
    });
    return resultArray;
  };

  const series = [
    {
      name: 'Total Costs',
      data: getTotalCost()
    },
    {
      name: 'Total Revenue',
      data: getTotalRevenue()
    },
    {
      name: 'Total Profits',
      data: getTotalProfit()
    }
  ];

  const chartData = {
    options: {
      chart: {
        id: 'linear-chart',
        foreColor: '#fff',
        toolbar: {
          show: false
        }
      },
      title: {
        text: 'Revenue ($)',
        style: {
          color: '#606060',
          fontSize: '14px',
          fontWeight: 400
        }
      },
      forecastDataPoints: {
        // change this to 12 to see the forecast
        count: 0
      },
      colors: ['#F55C3D', '#545DFF', '#06C630'],
      xaxis: {
        categories
      },
      yaxis: {
        axisBorder: {
          show: true
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: ['#C2290A', '#545DFF', '#048B21'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100]
        }
      },
      tooltip: {
        theme: 'dark'
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        }
      }
    },
    series
  };

  return (
    <div>
      {isBrowser && (
        <ReactApexChart
          options={{ ...chartData.options, legend: { position: 'right' } }}
          series={chartData.series}
          type="line"
          height={350}
        />
      )}
    </div>
  );
};

export default RoiCalculatorGraphic;
