import './styles/host-page.css';

import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import useWindowSize from '../../hooks/useWindowSize';
import HostImageMobile from '../../images/svgs/host/host-mobile.svg';
import HostImage from '../../images/svgs/host/host-presentation.svg';
import Button from '../Button';
import CaptionedTitle from '../CaptionedTitle';
import Markdown from '../Markdown';
import PageHeader from '../PageHeader';
import { BlockRow } from '../Rows';

const HostPageHeader: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    header: { title, caption, description }
  } = data.edges[0].node;
  const { isMobile } = useWindowSize();

  return (
    <PageHeader className="host-page-header">
      <Row>
        <Col md={5} lg={8} style={{ paddingTop: 40 }}>
          <CaptionedTitle as="h2" caption={caption}>
            {title}
          </CaptionedTitle>
          <BlockRow style={{ marginTop: 30 }}>
            <Col md={5} lg={7}>
              <Markdown className="description-host-gray" content={description} />
              <ButtonStyled primary>Preorder now</ButtonStyled>
            </Col>
          </BlockRow>
        </Col>
        <Col
          md={3}
          lg={4}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            right: isMobile ? 0 : 200,
            marginBottom: isMobile ? 50 : 0
          }}
        >
          {isMobile ? <HostImageMobile /> : <HostImage />}
        </Col>
      </Row>
    </PageHeader>
  );
};

const ButtonStyled = styled(Button)`
  width: 30%;
  margin: 30px 0px;
  & > button {
    background-color: #292929;
    border-image: none;
    min-height: 42px;
    min-width: 128px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
  }

  & > button:disabled {
    color: #fff;
  }
`;

const query = graphql`
  query {
    data: allHostPageYaml {
      edges {
        node {
          header {
            title
            caption
            description
          }
        }
      }
    }
  }
`;

export default HostPageHeader;
