import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col, Container, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import useWindowSize from '../../hooks/useWindowSize';
import deviceBreakPoints from '../../styles/breakpoints';
import { renderIcon } from '../../utils/icons';
import Markdown from '../Markdown';
import ThemedBackground from '../ThemedBackground';

const CherryPickedSection: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    cherryPickedSection: { title, items }
  } = data.edges[0].node;
  const { isMobile } = useWindowSize();

  return (
    <ThemedBackground as="section">
      <Container style={{ paddingBottom: isMobile ? 20 : 60 }}>
        <Col>
          <Title>{title}</Title>
          <Row>
            {items.map((item, index) => (
              <Col md={2.5} key={`item-${index}`} style={{ backgroundColor: '#181818', margin: 10, padding: 15 }}>
                <IconContainer>{renderIcon(item.icon, true, 'icon-versatile')}</IconContainer>
                <Markdown className="description-host-gray" content={item.description} />
              </Col>
            ))}
          </Row>
        </Col>
      </Container>
    </ThemedBackground>
  );
};

export default CherryPickedSection;

const Title = styled.h4`
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;

  @media ${deviceBreakPoints.mobile} {
    margin-top: 60px;
  }
`;

const IconContainer = styled.div`
  height: 40px;
`;

const query = graphql`
  query {
    data: allHostPageYaml {
      edges {
        node {
          cherryPickedSection {
            title
            items {
              description
              icon
            }
          }
        }
      }
    }
  }
`;
