import { Col, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import { ItemCostSectionItem } from '../../types/roicalculator';
import Markdown from '../Markdown';
import { Checkbox } from './styles/RoiCalculatorCost';

type Props = {
  items: ItemCostSectionItem[];
  checkElectronic: { [key: string]: boolean };
  handleCheckElectronic: (event: React.ChangeEvent<HTMLInputElement>, key: string, price: number) => void;
  checkParts: { [key: string]: boolean };
};

const RoiElectronicParts = ({ items, checkElectronic, handleCheckElectronic, checkParts }: Props) => {
  return (
    <div>
      {checkParts['subitem-3'] &&
        items?.map((subsubitem) => {
          const key = subsubitem.code;
          const price = Number(subsubitem?.description?.split('$')[1]);

          return (
            <RowStyled
              key={key}
              onClick={() =>
                handleCheckElectronic(
                  { target: { checked: !checkElectronic[key] } } as React.ChangeEvent<HTMLInputElement>,
                  key,
                  price
                )
              }
            >
              <Col md={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {subsubitem.type === 'checkbox' && (
                  <Checkbox
                    style={{ marginLeft: 0 }}
                    checked={checkElectronic[key] || false}
                    onChange={(event) => handleCheckElectronic(event, key, price)}
                  />
                )}
                {subsubitem.type === 'radiobutton' && (
                  <RadioButton
                    checked={checkElectronic[key] || false}
                    onChange={(event) => handleCheckElectronic(event, key, price)}
                  />
                )}
                <span>{subsubitem.title}</span>
              </Col>
              <Col md={4} style={{ alignItems: 'flex-end' }}>
                <Markdown className="description-host-gray" content={subsubitem?.description || ''} />
              </Col>
            </RowStyled>
          );
        })}
    </div>
  );
};

const RowStyled = styled(Row)<{ onClick: () => void }>`
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-grey-900);
  }
`;

const RadioButton = styled.input.attrs({ type: 'radio' })`
  margin-right: 15px;
  height: 25px;
  width: 25px;
  appearance: none;
  border: 1.5px solid #8f8f8f;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    margin: 4px;
  }

  &:checked {
    border: 1.5px solid #e6e6e6;
  }

  &:checked::after {
    background-color: #e6e6e6;
  }

  &:hover::after {
    background-color: #e6e6e6;
  }
`;

export default RoiElectronicParts;
