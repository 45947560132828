import { ItemCostSection } from '../../types/roicalculator';
import Markdown from '../Markdown';

type Props = {
  code: string;
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  subitem: ItemCostSection;
};

const InputCost = ({ code, value, handleChange, subitem }: Props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        style={{
          width: 150,
          marginRight: 10,
          height: 40,
          paddingRight: 10,
          textAlign: 'right',
          backgroundColor: '#0b0b0b',
          color: '#FAFAFA',
          borderWidth: 0
        }}
        value={value}
        type="text"
        onChange={(event) => handleChange(event, code)}
      />
      <div style={{ width: 30, display: 'flex', justifyContent: 'center' }}>
        <Markdown className="description-host-gray" content={subitem?.description} />
      </div>
    </div>
  );
};

export default InputCost;
