import { ItemCostSection } from '../types/roicalculator';
const getCheckElectronics = (checkElectronic: { [key: string]: boolean | number }, status: boolean) => {
  return {
    'raspberry-pi': status,
    screen: status,
    '12v-power-supply': status,
    '5v-power-supply': status,
    nv10: status,
    nv9: false,
    nv200: false,
    nde1000: status,
    printer: status
  };
};

const getPrice = (priceString: string) => {
  return Number(priceString.replace(/[^0-9.-]+/g, ''));
};

type Props = {
  quantity: number;
  totalOther: number;
  totalElectronic: number;
  item: ItemCostSection;
  costMetrics: { [key: string]: number };
  exploitMetrics: { [key: string]: number };
};

const getDescription = ({ quantity, totalOther, totalElectronic, item, costMetrics, exploitMetrics }: Props) => {
  // initial investment
  const itemInvestment = costMetrics['subitem-0'];
  const total = (itemInvestment + totalOther + totalElectronic) * quantity;

  // total Buy volume per month
  const buyVolumePerMonth = exploitMetrics['buytx'];
  const priceBuy = buyVolumePerMonth * quantity;
  const buyAvg = exploitMetrics['buyavg'];
  const buyTotal = priceBuy * buyAvg;

  // total Sell volume per month
  const sellVolumePerMonth = exploitMetrics['selltx'];
  const priceSell = sellVolumePerMonth * quantity;
  const sellAvg = exploitMetrics['sellavg'];
  const sellTotal = priceSell * sellAvg;

  // cash handling per month
  const cashHandling = buyTotal - sellTotal;
  const cashCost = cashHandling * (costMetrics['subitem-2'] * 0.01);

  // total cost per month
  const itemRent = costMetrics['subitem-1'];
  const itemRunning = costMetrics['subitem-3'];
  const totalCost = itemRent * quantity + itemRunning * quantity + cashCost;

  // Revenue per month
  const revenueTotal = buyTotal * (exploitMetrics['buy'] * 0.01) + sellTotal * (exploitMetrics['sell'] * 0.01);

  switch (item.code) {
    case 'initial-investment':
      return `$${total.toFixed(0)}`;
    case 'total-cost':
      return `$${totalCost.toFixed(0)}`;
    case 'revenue':
      return `$${revenueTotal.toFixed(0)}`;
    case 'gross-profit':
      return `$${(revenueTotal - totalCost).toFixed(0)}`;
    default:
      return item.description;
  }
};

export { getPrice, getDescription, getCheckElectronics };
