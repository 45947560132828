import { graphql, useStaticQuery } from 'gatsby';
import { FC, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import { TotalItem } from '../../types/roicalculator';
import { getCheckElectronics, getDescription } from '../../utils/RoiCalculatorFormat';
import Markdown from '../Markdown';
import ThemedBackground from '../ThemedBackground';
import RoiCalculatorCost from './RoiCalculatorCost';
import RoiCalculatorGraphic from './RoiCalculatorGraphic';

const initialCashInDeviceNv10 = 250; // initial radio button nv10 selected
const initialElectronicParts = 1270; // not include chashInDevice radio button
const initialTotalOther = 1600; // not include electronic parts total

const RoiCalculatorSection: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    roicalculatorSection: { title, items, totals }
  } = data.edges[0].node;

  const [totalElectronic, setTotalElectronic] = useState(initialElectronicParts);
  const [totalOther, setTotalOther] = useState(initialTotalOther);
  const [totalCost, setTotalCost] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [costPerMonth, setCostPerMonth] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [checkElectronic, setCheckElectronic] = useState<{ [key: string]: boolean }>(getCheckElectronics({}, true));
  const [totalCashInDevice, setTotalCashInDevice] = useState(initialCashInDeviceNv10);
  const [checkParts, setCheckParts] = useState<{ [key: string]: boolean }>({
    'subitem-0': true,
    'subitem-1': true,
    'subitem-2': true,
    'subitem-3': true
  });
  const [costMetrics, setCostMetrics] = useState<{ [key: string]: number }>({
    'subitem-0': 75,
    'subitem-1': 110,
    'subitem-2': 2,
    'subitem-3': 35
  });

  const [exploitMetrics, setExploitMetrics] = useState<{ [key: string]: number }>({
    buy: 4,
    sell: 3,
    buytx: 30,
    buyavg: 310,
    selltx: 10,
    sellavg: 460
  });

  const handleCheckParts = (event: React.ChangeEvent<HTMLInputElement>, key: string, price: number) => {
    const { checked } = event.target;

    if (key === 'subitem-3') {
      if (checked) {
        setTotalElectronic(initialElectronicParts);
        setTotalCashInDevice(initialCashInDeviceNv10);
        setCheckElectronic(getCheckElectronics(checkElectronic, true));
      } else {
        setTotalElectronic(0);
        setTotalCashInDevice(0);
        setCheckElectronic(getCheckElectronics(checkElectronic, false));
      }
      setCheckParts({ ...checkParts, [key]: checked, 'subitem-0': false });
      const total = checkParts['subitem-0'] && checked ? 0 : totalOther;
      setTotalOther(total);
    } else {
      if (key === 'subitem-0') {
        const status = checked ? true : false;
        setCheckParts({ 'subitem-1': status, 'subitem-2': status, 'subitem-3': status, [key]: checked });
        setCheckElectronic(getCheckElectronics(checkElectronic, status));
        setTotalElectronic(status ? initialElectronicParts : 0);
        setTotalCashInDevice(status ? initialCashInDeviceNv10 : 0);
        setTotalOther(checked ? initialTotalOther : 0);
      } else {
        setCheckParts({ ...checkParts, [key]: checked, 'subitem-0': false });
        const total = checkParts['subitem-0'] && checked ? 0 : totalOther;
        setTotalOther(checked ? total + price : total - price);
      }
    }
  };

  const cashInDeviceSelect = (checked: boolean, key: string) => {
    const cashInDevices: { [key: string]: boolean } = {
      nv10: false,
      nv9: false,
      nv200: false
    };
    if (checked && key === 'nv10') {
      cashInDevices.nv10 = true;
      cashInDevices.nv9 = false;
      cashInDevices.nv200 = false;
    }
    if (checked && key === 'nv9') {
      cashInDevices.nv10 = false;
      cashInDevices.nv9 = true;
      cashInDevices.nv200 = false;
    }
    if (checked && key === 'nv200') {
      cashInDevices.nv10 = false;
      cashInDevices.nv9 = false;
      cashInDevices.nv200 = true;
    }
    if (!checked) {
      cashInDevices[key] = true;
    }
    return cashInDevices;
  };

  const handleCheckElectronic = (event: React.ChangeEvent<HTMLInputElement>, key: string, price: number) => {
    const { checked } = event.target;
    const priceCashInDevice = {
      nv10: 250,
      nv9: 450,
      nv200: 700
    };
    if (key === 'nv10' || key === 'nv9' || key === 'nv200') {
      const cashInDevices = cashInDeviceSelect(checked, key);
      setCheckElectronic({ ...checkElectronic, ...cashInDevices });
      const priceSelected = cashInDevices.nv10
        ? priceCashInDevice.nv10
        : cashInDevices.nv9
        ? priceCashInDevice.nv9
        : priceCashInDevice.nv200;
      setTotalCashInDevice(priceSelected);
      setCheckParts({ ...checkParts, 'subitem-0': false });
      return;
    }
    setCheckElectronic({ ...checkElectronic, [key]: checked });
    setTotalElectronic(checked ? totalElectronic + price : totalElectronic - price);
    setCheckParts({ ...checkParts, 'subitem-0': false });
  };

  const handleChangeCostMetric = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = event.target;
    const isNumber = /^[0-9]*$/gm.test(value);
    setCostMetrics({ ...costMetrics, [key]: Number(isNumber ? value : 0) });
  };

  const handleChangeExploitMetric = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = event.target;
    const isNumber = /^[0-9]*$/gm.test(value);
    setExploitMetrics({ ...exploitMetrics, [key]: Number(isNumber ? value : 0) });
  };

  const roiCostProps = {
    items,
    checkParts,
    handleCheckParts,
    totalOther,
    quantity,
    totalElectronic,
    totalCashInDevice,
    setQuantity,
    checkElectronic,
    handleCheckElectronic,
    costMetrics,
    handleChangeCostMetric,
    exploitMetrics,
    handleChangeExploitMetric
  };

  useEffect(() => {
    if (totals?.items?.length === 0) return;
    totals?.items?.map((item: TotalItem) => {
      const description = getDescription({
        quantity,
        totalOther,
        totalElectronic,
        item,
        costMetrics,
        exploitMetrics
      });
      if (item.code === 'initial-investment') setTotalCost(Number(description.replace(/[^0-9.-]+/g, '')));
      if (item.code === 'revenue') setRevenue(Number(description.replace(/[^0-9.-]+/g, '')));
      if (item.code === 'total-cost') setCostPerMonth(Number(description.replace(/[^0-9.-]+/g, '')));
    });

    return () => {
      setTotalCost(0);
      setRevenue(0);
      setCostPerMonth(0);
    };
  }, [totalOther, totalElectronic, quantity, totals?.items, costMetrics, exploitMetrics]);

  return (
    <ThemedBackground as="section" backgroundColor="#181818">
      <Container>
        <Col style={{ borderBottom: '1px solid #828282', paddingBottom: 0, marginBottom: 30 }}>
          <Title id="roi-calculator">{title}</Title>
        </Col>
        <Col
          style={{ padding: 0, marginBottom: 20, marginLeft: 10, fontSize: 18, fontStyle: 'normal', color: '#828282' }}
        >
          Estimate the profitability of your Swapbox fleet.
        </Col>
        <Col
          style={{ padding: 0, marginBottom: 50, marginLeft: 10, fontSize: 18, fontStyle: 'normal', color: '#828282' }}
        >
          Note: All prices are subject to change and are indicative only.
        </Col>
        <Row style={{ padding: 10, position: 'relative' }}>
          <Col md={4}>{items?.length > 0 && <RoiCalculatorCost {...roiCostProps} />}</Col>
          <Col md={4} style={{ paddingRight: 0 }}>
            <Col style={{ padding: 0 }}>
              <div style={{ position: 'sticky', top: 0 }}>
                <Card style={{ height: 400, paddingTop: 30 }}>
                  <RoiCalculatorGraphic totalCost={totalCost} revenue={revenue} costPerMonth={costPerMonth} />
                </Card>
                <TotalContainer>
                  {totals.items.map((item: TotalItem, index: number) => {
                    const description = getDescription({
                      quantity,
                      totalOther,
                      totalElectronic: totalElectronic + totalCashInDevice,
                      item,
                      costMetrics,
                      exploitMetrics
                    });
                    return (
                      <Card key={`total-${index}`} style={{ width: '42%', marginBottom: 20, padding: 20 }}>
                        <Markdown className="description-host-gray" content={item.title} />
                        <div style={{ textAlign: 'right' }}>
                          <Title style={{ fontSize: index === 0 ? 52 : 36 }}>{description}</Title>
                          <Markdown className="description-host-gray" content={item.subdescription || ''} />
                        </div>
                      </Card>
                    );
                  })}
                </TotalContainer>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </ThemedBackground>
  );
};

export default RoiCalculatorSection;

const Title = styled.h3`
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 15px;
`;

const Card = styled.div`
  background-color: #0b0b0b;
  height: 150px;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin-top: 20px;
  width: 100%;
`;

const query = graphql`
  query {
    data: allHostPageYaml {
      edges {
        node {
          roicalculatorSection {
            title
            items {
              title
              type
              code
              items {
                title
                subtitle
                help
                type
                code
                description
                subdescription
                isquantity
                items {
                  title
                  description
                  type
                  code
                }
              }
            }
            totals {
              items {
                title
                code
                description
                subdescription
              }
            }
          }
        }
      }
    }
  }
`;
