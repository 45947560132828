import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import CherryPickedSection from '../components/host-page/CherryPickedSection';
import FaqAboutHost from '../components/host-page/FaqAboutHost';
import HostPageHeader from '../components/host-page/HostPageHeader';
import ReadyToHost from '../components/host-page/ReadyToHost';
import RoiCalculatorSection from '../components/host-page/RoiCalculatorSection';
import SpecSection from '../components/host-page/SpecSection';
import VersatileSection from '../components/host-page/VersatileSection';
import WhyChoose from '../components/host-page/WhyChoose';
import Page from '../components/Page';
import { darkTheme } from '../styles/themes';

const HostPage = () => {
  const { data } = useStaticQuery(query);
  const { seo } = data.edges[0].node;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const isMobileCheck = () => {
      const check = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      return check;
    };

    setIsMobile(isMobileCheck());

    return () => {
      setIsMobile(false);
    };
  }, []);

  return (
    <Page seoTitle={seo.title} seoDescription={seo.description}>
      <ThemeProvider theme={darkTheme}>
        <HostPageHeader />
        <WhyChoose />
        <VersatileSection />
        <CherryPickedSection />
        <SpecSection />
        {!isMobile && <RoiCalculatorSection />}
        <ReadyToHost />
        <FaqAboutHost />
      </ThemeProvider>
    </Page>
  );
};

const query = graphql`
  query {
    data: allOrderPageYaml {
      edges {
        node {
          seo {
            title
            description
          }
        }
      }
    }
  }
`;

export default HostPage;
