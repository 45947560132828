import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col, Container, Row } from 'react-awesome-styled-grid';

import useWindowSize from '../../hooks/useWindowSize';
import CaptionedTitle from '../CaptionedTitle';
import CollapsibleList from '../CollapsibleList';
import Markdown from '../Markdown';
import ThemedBackground from '../ThemedBackground';

type Item = {
  title: string;
  description: string;
};

const FaqAboutHost: FC = () => {
  const { data } = useStaticQuery(query);
  const { title, items } = data.edges[0].node;
  const { isMobile } = useWindowSize();

  return (
    <ThemedBackground as="section">
      <Container>
        <Col style={{ paddingTop: isMobile ? 80 : 150, marginBottom: 40 }}>
          <CaptionedTitle className="caption-why-choose" as="h3">
            {title}
          </CaptionedTitle>
        </Col>
        <Row style={{ padding: 5, justifyContent: 'start' }}>
          {items.map((item: Item, index: number) => {
            return (
              <Col key={`item-${index}`} md={3.94} style={{ margin: 5 }}>
                <CollapsibleList
                  title={item.title}
                  titleStyle={{ fontSize: 20 }}
                  containerStyle={{ backgroundColor: '#181818', border: 'none' }}
                  contentStyle={{ marginTop: 10, color: '#8F8F8F', fontSize: 16 }}
                  backgroundColorOpen="#292929"
                >
                  <Markdown content={item.description} className="description-host-gray" />
                </CollapsibleList>
              </Col>
            );
          })}
        </Row>
      </Container>
    </ThemedBackground>
  );
};

export default FaqAboutHost;

const query = graphql`
  query {
    data: allFaqhostYaml {
      edges {
        node {
          title
          items {
            title
            description
          }
        }
      }
    }
  }
`;
