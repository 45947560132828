import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FC } from 'react';
import { Col, Container, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import useWindowSize from '../../hooks/useWindowSize';
import AtmFronImage from '../../images/svgs/host/front-atm.svg';
import AtmSideImage from '../../images/svgs/host/side-atm.svg';
import deviceBreakPoints from '../../styles/breakpoints';
import Markdown from '../Markdown';
import ThemedBackground from '../ThemedBackground';

type Item = {
  title: string;
  description: string;
};

type Mobile = {
  isMobile: boolean | undefined;
};

const ImageSection = ({ isMobile }: Mobile) => {
  return (
    <Row style={{ marginTop: isMobile ? 20 : 50, padding: 0 }}>
      <Col md={4} style={{ alignItems: !isMobile ? 'start' : 'center' }}>
        <AtmFrontImage />
      </Col>
      <Col md={4} style={{ alignItems: !isMobile ? 'end' : 'center', marginTop: isMobile ? 10 : 0 }}>
        <AtmSidesImage />
      </Col>
    </Row>
  );
};

const ButtonSection = ({ isMobile }: Mobile) => {
  return (
    <Row style={{ marginTop: isMobile ? 20 : 50, padding: 0 }}>
      <Col xs={2} md={4} style={{ alignItems: 'end' }}>
        <ButtonPreorder>Preorder now</ButtonPreorder>
      </Col>
      <Col xs={2} md={4} style={{ alignItems: 'start' }}>
        <ButtonVisitDoc to="/docs">Visit the docs</ButtonVisitDoc>
      </Col>
    </Row>
  );
};

const SpecSection: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    specificationSection: { title, image, items }
  } = data.edges[0].node;

  const { isMobile } = useWindowSize();

  const rowStyle = {
    borderTop: '1px solid #828282',
    padding: '15px 0',
    margin: 5
  };

  return (
    <ThemedBackground as="section" pb={!isMobile} id="specs">
      <Container style={{ paddingBottom: isMobile ? 60 : 0 }}>
        <Col style={{ padding: 10 }}>
          <Title>{title}</Title>
          {items.map((item: Item, index: number) => {
            const lastStyle = index === items.length - 1 ? { borderBottom: '1px solid #606060' } : {};
            return (
              <Row key={`item-${index}`} style={{ ...rowStyle, ...lastStyle }}>
                <Col xs={2} md={4} style={{ color: '#828282', padding: 0 }}>
                  {item.title}
                </Col>
                <Col xs={2} md={4}>
                  <Markdown className="description-host-gray" content={item.description} />
                </Col>
              </Row>
            );
          })}
          {!isMobile ? (
            <>
              <ImageSection isMobile={isMobile} />
              <ButtonSection isMobile={isMobile} />
            </>
          ) : (
            <>
              <ButtonSection isMobile={isMobile} />
              <ImageSection isMobile={isMobile} />
            </>
          )}

          <Col>
            <GatsbyImage style={{ marginTop: 50 }} image={image.childImageSharp.gatsbyImageData} alt="image" />
          </Col>
        </Col>
      </Container>
    </ThemedBackground>
  );
};

export default SpecSection;

const Title = styled.h4`
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 25px;
  border-top: 1px solid #3c3c3c;
  padding-top: 15px;
  padding-left: 5px;

  @media ${deviceBreakPoints.mobile} {
    margin-top: 60px;
  }
`;

const ButtonPreorder = styled.button`
  background-color: #d6d6d6;
  border: 1px solid #292929;
  color: #292929;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 60px;
  margin-top: 20px;
  margin-bottom: 20px;
  /* cursor: pointer; */
  cursor: not-allowed;

  @media ${deviceBreakPoints.mobile} {
    padding: 10px 34px;
  }

  &:hover {
    background-color: #ffffff;
  }
`;

const ButtonVisitDoc = styled(Link)`
  background-color: #292929;
  border: 1px solid #292929;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 60px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;

  @media ${deviceBreakPoints.mobile} {
    padding: 10px 34px;
    text-align: center;
  }

  &:hover {
    background-color: #3c3c3c;
  }
`;

const AtmFrontImage = styled(AtmFronImage)`
  width: 100%;
  height: auto;
  @media ${deviceBreakPoints.mobile} {
    width: 100%;
    height: 100%;
  }
`;

const AtmSidesImage = styled(AtmSideImage)`
  width: 100%;
  height: auto;
  @media ${deviceBreakPoints.mobile} {
    width: 100%;
    height: 100%;
  }
`;

const query = graphql`
  query {
    data: allHostPageYaml {
      edges {
        node {
          specificationSection {
            title
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
              }
            }
            items {
              title
              description
            }
          }
        }
      }
    }
  }
`;
