import { Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

const TooltipText = styled.div`
  background: #292929;
  color: #fff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
`;

const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 30px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 150px;
  padding: 5px 5px;
  border-radius: 4px;
  font-size: 12px;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s, padding 0.5s ease-in-out;
  &:before {
    content: '';
    width: 0;
    height: 0;
    left: 40px;
    top: -10px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
  z-index: 1;
`;

const TooltipCard = styled.div`
  margin-left: 10px;
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 250px;
    padding: 8px 8px;
    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8);
    }
  }
`;

const RowStyled = styled(Row)<{ onClick: () => void }>`
  &:hover {
    background-color: var(--color-grey-900);
  }
`;

const ButtonQuantity = styled.button`
  height: 20px;
  width: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #0b0b0b;
  color: #fafafa;
  border-color: #fafafa;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 25px;
  height: 25px;
  margin-right: 15px;
  margin-left: 13px;
  appearance: none;
  border: 1px solid #8f8f8f;
  position: relative;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: #e6e6e6;
    border: 1px solid #e6e6e6;
    color: #0b0b0b;
  }

  &:checked:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 10px;
    background: #0b0b0b;
    width: 2px;
    height: 3px;
    box-shadow: 2px 0 0 #0b0b0b, 4px 0 0 #0b0b0b, 4px -2px 0 #0b0b0b, 4px -4px 0 #0b0b0b, 4px -6px 0 #0b0b0b,
      4px -8px 0 #0b0b0b;
    transform: rotate(45deg);
  }
`;

export { TooltipText, TooltipBox, TooltipCard, RowStyled, ButtonQuantity, Checkbox };
