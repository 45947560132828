import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col, Container, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import useWindowSize from '../../hooks/useWindowSize';
import deviceBreakPoints from '../../styles/breakpoints';
import { renderIcon } from '../../utils/icons';
import CaptionedTitle from '../CaptionedTitle';
import Markdown from '../Markdown';
import ThemedBackground from '../ThemedBackground';

type Item = {
  title: string;
  description: string;
  icon: string;
};

const WhyChoose: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    whyChooseSection: { title, description, items }
  } = data.edges[0].node;
  const { isMobile } = useWindowSize();

  return (
    <ThemedBackground as="section" pb id="why">
      <Container>
        <Col>
          {isMobile ? (
            <Title style={{ fontSize: 38 }}>{title}</Title>
          ) : (
            <CaptionedTitle className="caption-why-choose" as="h3">
              {title}
            </CaptionedTitle>
          )}
          <DescriptionContainer>
            <Markdown className="description-host-gray" content={description} />
          </DescriptionContainer>
        </Col>
        <Row style={{ padding: 5, justifyContent: 'start' }}>
          {items.map((item: Item, index: number) => {
            const extraClass = index !== 0 && index !== 3 ? 'none' : '';
            return (
              <Col key={`item-${index}`} md={1.9} lg={2.9} xl={2.9} style={{ margin: 5, padding: 15 }}>
                <IconContainer>{renderIcon(item.icon, true, extraClass)}</IconContainer>
                <Title>{item.title}</Title>
                <Markdown className="description-host-gray" content={item.description} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </ThemedBackground>
  );
};

export default WhyChoose;

const DescriptionContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 70%;

  @media ${deviceBreakPoints.mobile} {
    width: 100%;
  }
`;

const IconContainer = styled.div`
  height: 25px;
`;

const Title = styled.h4`
  font-size: 24px;
  line-height: 1.33;
  margin-bottom: 10px;
  font-weight: medium;
`;

const query = graphql`
  query {
    data: allHostPageYaml {
      edges {
        node {
          whyChooseSection {
            title
            description
            items {
              title
              description
              icon
            }
          }
        }
      }
    }
  }
`;
